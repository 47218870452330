/** LPページURL */
export const LP_BASE_URL = process.env.LP_BASE_URL || '';

/** 講師・生徒ユーザー閲覧用「利用規約」リンク */
export const TERMS_URL = `/terms`;

/** 講師ユーザー閲覧用「プライバシーポリシー」リンク */
export const PRIVACY_URL = `/privacy`;

/** 講師ユーザー閲覧用「特商法」リンク */
export const TOKUTEI_URL = `/tokutei`;
