import { useAuthContext } from '@/hooks/auth/';
import { useSchoolContext } from '@/hooks/school/';
import Link from 'next/link';
import { useMemo } from 'react';

export const ContactFormLink: React.FC = () => {
  const { school } = useSchoolContext();
  const { currentUser } = useAuthContext();

  const contactUrl = useMemo(() => {
    const schoolName = encodeURIComponent(school?.name ?? '');
    const name = encodeURIComponent(currentUser?.name ?? '');
    const email = encodeURIComponent(currentUser?.email ?? '');
    return `https://docs.google.com/forms/d/e/1FAIpQLSdx7Hvs0v2PVTUgJNWQYM79g-q_OxKlrK3ly6rQlATOWKcicA/viewform?usp=pp_url&entry.1770367533=${schoolName}&entry.910791352=${name}&entry.2060238178=${email}`;
  }, [school, currentUser]);

  return (
    <Link href={contactUrl}>
      <a target="_blank">問い合わせ</a>
    </Link>
  );
};
