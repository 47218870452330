import Link from 'next/link';
import styles from './GlobalFooter.scss';
import { pagesPath } from '@/lib/$path';
import { TERMS_URL } from '@/config/urlConfig';
import { ContactFormLink } from '@/components/atoms/link/ContactFormLink';

type FooterProps = JSX.IntrinsicElements['footer'];
type Props = FooterProps;

/** organisms/GlobalFooter */
export const GlobalFooter: React.FC<Props> = () => {
  return (
    <footer className="global-footer">
      <ul className="global-footer-nav">
        <li>
          <Link href={pagesPath.privacy.$url()}>
            <a target="_blank" rel="noreferrer">
              プライバシーポリシー
            </a>
          </Link>
        </li>
        <li>
          <Link href={pagesPath.tokutei.$url()}>
            <a target="_blank" rel="noreferrer">
              特定商取引法に基づく表記
            </a>
          </Link>
        </li>
        <li>
          <a href={TERMS_URL} target="_blank" rel="noreferrer">
            利用規約
          </a>
        </li>
        <li>
          <ContactFormLink></ContactFormLink>
        </li>
      </ul>
      <style jsx>{styles}</style>
    </footer>
  );
};
