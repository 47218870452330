import Link from 'next/link';
import styles from './GlobalHeader.scss';
import { pagesPath } from '@/lib/$path';
import { MouseEvent, useState } from 'react';
import { useRouter } from 'next/dist/client/router';
import { UserState } from '@/hooks/auth/authInterface';
import { INITIAL_PAGINATION_QUERY } from '@/config/pathConfig';

import { MenuBtn } from './MenuBtn';
import { SchoolName } from '@/components/atoms/SchoolName';

type HeaderProps = JSX.IntrinsicElements['header'];
type GlobalHeader = {
  signOutFn: () => Promise<void>;
  isLoggedIn: boolean;
  currentUser: UserState;
};
type Props = HeaderProps & GlobalHeader;

/** organisms/GlobalHeader */
export const GlobalHeader: React.FC<Props> = ({ signOutFn, isLoggedIn, currentUser }) => {
  const router = useRouter();

  const signOut = async (e: MouseEvent) => {
    e.preventDefault();
    await signOutFn();
  };

  const [showMenu, setShowMenu] = useState(false);
  const menuToggle = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      {(() => {
        if (isLoggedIn) {
          return (
            <MenuBtn right width={250}>
              <ul className="global-header__account-menu">
                <li className="global-header__account-item">
                  <Link href={pagesPath.news.$url()}>
                    <a>お知らせ</a>
                  </Link>
                </li>
                <li className="global-header__account-item">
                  <Link href={pagesPath.account.payment.histories.$url({ query: INITIAL_PAGINATION_QUERY })}>
                    <a>決済履歴</a>
                  </Link>
                </li>
                <li className="global-header__account-item">
                  <Link href={pagesPath.account.payment.methods.$url()}>
                    <a>決済方法</a>
                  </Link>
                </li>
                <li className="global-header__account-item">
                  <Link href={pagesPath.account.$url()}>
                    <a>アカウント情報</a>
                  </Link>
                </li>
                <li className="global-header__account-item">
                  <a href="#" onClick={signOut}>
                    ログアウト
                  </a>
                </li>
              </ul>
            </MenuBtn>
          );
        } else {
          return (
            <MenuBtn right width={250}>
              <ul className="global-header__account-menu">
                <li className="global-header__account-item">
                  <Link href={pagesPath.news.$url()}>
                    <a>お知らせ</a>
                  </Link>
                </li>
                <li className="global-header__account-item">
                  <Link href={pagesPath.sign_in.$url({ query: { referrer: router.asPath } })}>
                    <a>ログイン</a>
                  </Link>
                </li>
                <li className="global-header__account-item">
                  <div className="signup-button">
                    <Link href={pagesPath.sign_up.$url({ query: { referrer: router.asPath } })}>
                      <a className="signup-button-inner">新規登録</a>
                    </Link>
                  </div>
                </li>
              </ul>
            </MenuBtn>
          );
        }
      })()}
      <header className="global-header">
        <h1 className="global-header__school-name">
          <Link href={pagesPath.$url()} passHref>
            <SchoolName size="small" />
          </Link>
        </h1>
        {(() => {
          if (isLoggedIn) {
            return (
              <>
                <div className="pc-only header-balloon">
                  <button
                    type="button"
                    onClick={() => menuToggle()}
                    className={`header-balloon-button ${showMenu && 'on'}`}
                  >
                    {currentUser.name}
                  </button>
                  <div className={`header-balloon-menu ${showMenu && 'menu-show'}`}>
                    <nav className="global-header__nav">
                      <ul className="global-header__nav-menu">
                        <li className="global-header__nav-item">
                          {/* {currentUser.email} */}
                          <ul className="global-header__account-menu">
                            <li className="global-header__account-item">
                              <Link href={pagesPath.news.$url()}>
                                <a>お知らせ</a>
                              </Link>
                            </li>
                            <li className="global-header__account-item">
                              <Link
                                href={pagesPath.account.payment.histories.$url({ query: INITIAL_PAGINATION_QUERY })}
                              >
                                <a>決済履歴</a>
                              </Link>
                            </li>
                            <li className="global-header__account-item">
                              <Link href={pagesPath.account.payment.methods.$url()}>
                                <a>決済方法</a>
                              </Link>
                            </li>
                            <li className="global-header__account-item">
                              <Link href={pagesPath.account.$url()}>
                                <a>アカウント情報</a>
                              </Link>
                            </li>
                            <li className="global-header__account-item">
                              <a href="#" onClick={signOut}>
                                ログアウト
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="sp-only spacer"></div>
              </>
            );
          } else {
            return (
              <nav className="global-header__nav">
                <ul className="global-header__nav-menu">
                  <li className="global-header__nav-item pc-only">
                    <Link href={pagesPath.sign_in.$url({ query: { referrer: router.asPath } })}>
                      <a>ログイン</a>
                    </Link>
                  </li>
                  <li className="global-header__nav-item">
                    <div className="signup-button">
                      <Link href={pagesPath.sign_up.$url({ query: { referrer: router.asPath } })}>
                        <a className="signup-button-inner">新規登録</a>
                      </Link>
                    </div>
                  </li>
                  <li className="global-header__nav-item spacer sp-only"></li>
                </ul>
              </nav>
            );
          }
        })()}
        <style jsx>{styles}</style>
      </header>
    </>
  );
};
