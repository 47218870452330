import Link from 'next/link';
import styles from './NewsBar.scss';
import { pagesPath } from '@/lib/$path';
import { useImportantNews } from '@/hooks/news/newsHooks';

export const NewsBar: React.FC = () => {
  const { data } = useImportantNews();

  if (!data) return <></>;

  return (
    <Link href={pagesPath.news._newsId(data.id).$url()}>
      <a className="news-banner">
        {data.title}
        <style jsx>{styles}</style>
      </a>
    </Link>
  );
};
