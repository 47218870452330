import { useSchoolContext } from '@/hooks/school';
import { classNames } from '@/lib/classNames';
import { AnchorHTMLAttributes } from 'react';
import styles from './SchoolName.scss';

type SchoolName = {
  size?: 'medium' | 'small';
};

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & SchoolName;

export const SchoolName: React.FC<Props> = (props) => {
  const { school } = useSchoolContext();
  if (!school) return <a></a>;
  const { name, logo_url } = school;
  return (
    <a className={classNames(['school-name', props.size])} {...props}>
      {logo_url ? <img src={logo_url} alt={name} className="logo" /> : name}
      <style jsx>{styles}</style>
    </a>
  );
};
