import { getDetail, getImportant, getList } from '@/lib/news/news';
import useSWR from 'swr';

export const useImportantNews = () => {
  return useSWR('importantNews', getImportant);
};

export const useNewsList = ({ limit, offset }: { limit: number; offset: number }) => {
  return useSWR(['news-list', limit, offset], (_, limit, offset) => {
    return getList({ limit, offset });
  });
};

export const useNewsDetail = (id: string) => {
  return useSWR(['news-detail', id], (_, id) => {
    return getDetail(id);
  });
};
