import styles from './GlobalLayout.scss';
import Head from 'next/head';

import { GlobalHeader } from '@/components/organisms/layout/GlobalHeader';
import { GlobalFooter } from '@/components/organisms/layout/GlobalFooter';
import { useAuthContext } from '@/hooks/auth';
import { useSchoolContext } from '@/hooks/school';
import { NewsBar } from '@/components/molecules/news/NewsBar';

type DivProps = JSX.IntrinsicElements['div'];
type GlobalLayout = {
  full?: boolean;
  header?: boolean;
  footer?: boolean;
  color?: string;
  hideNewsBar?: boolean;
};
type Props = DivProps & GlobalLayout;

/** organizers/GlobalLayout */
export const GlobalLayout: React.FC<Props> = ({ children, header, footer, color, full, hideNewsBar }) => {
  const authCtx = useAuthContext();
  const schoolCtx = useSchoolContext();
  const { school } = schoolCtx;

  return (
    <div className="global-layout" style={{ background: color }}>
      <Head>
        <link rel="icon" href={school?.icon_url || '/favicon.ico'} />
        {school?.icon_url && <link rel="apple-touch-icon" href={school?.icon_url}></link>}
      </Head>
      {header === false || (
        <GlobalHeader signOutFn={authCtx.signOut} isLoggedIn={authCtx.isLoggedIn} currentUser={authCtx.currentUser} />
      )}

      {!hideNewsBar && <NewsBar />}

      <div className={['global-layout__inner', full ? 'width-full' : ''].filter(Boolean).join(' ')}> {children}</div>

      {footer === false || <GlobalFooter />}
      <style jsx>{styles}</style>
    </div>
  );
};
