import { createClient } from 'microcms-js-sdk';
import { MicroCMSFilterQuery } from 'microcms-filter-query';

const endpoint = 'teachyou-news';

// Initialize Client SDK.
const client = createClient({
  serviceDomain: 'chot',
  apiKey: '0e03875ee0724e219949df9c21902848cdbe'
});

export type NewsListItem = {
  id: string;
  title: string;
  body: string;
  publishAt: string;
  important: boolean;
};

type NewsListResponse = {
  contents: Array<NewsListItem>;
  totalCount: number;
  limit: number;
  offset: number;
};

/** 重要なお知らせを返却します */
export const getImportant = async (): Promise<NewsListItem | null> => {
  const now = new Date().toISOString();
  const filters = new MicroCMSFilterQuery()
    .equals('important', 'true')
    .and()
    .lessThan('publishAt', now)
    .and()
    ._parentheses(new MicroCMSFilterQuery().greaterThan('closeAt', now).or().notExists('closeAt').$execute())
    .$execute();

  const queries = { fields: 'title,id', filters, orders: '-publishAt', limit: 1 };
  const res = await client.getList({
    endpoint,
    queries
  });

  const item = res.contents[0];
  return item;
};

/** お知らせのリストを返却します */
export const getList = async ({ limit, offset }: { limit: number; offset: number }): Promise<NewsListResponse> => {
  const now = new Date().toISOString();
  const filters = new MicroCMSFilterQuery()
    .lessThan('publishAt', now)
    .and()
    ._parentheses(new MicroCMSFilterQuery().greaterThan('closeAt', now).or().notExists('closeAt').$execute())
    .$execute();
  const queries = {
    limit,
    offset,
    orders: '-publishAt',
    filters
  };

  return (await client.getList({ endpoint, queries })) as NewsListResponse;
};

/** お知らせ詳細を返却します */
export const getDetail = async (contentId: string): Promise<NewsListItem> => {
  const res = await client.get({
    endpoint,
    contentId
  });
  return res as NewsListItem;
};
