import { slide as Menu, Props as MenuProps } from 'react-burger-menu';

type Props = MenuProps;

export const MenuBtn: React.FC<Props> = (props) => {
  return (
    <>
      <Menu {...props}>{props.children ? props.children : ''}</Menu>
    </>
  );
};
